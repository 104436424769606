@font-face {
  font-family: LibreFranklin;
  src: url("librefranklin-medium-webfont.bdcd11d7.woff2") format("woff2"), url("librefranklin-medium-webfont.365ea7d2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  height: 100vh;
  margin: 0;
  font-family: LibreFranklin;
}

#app {
  width: 100%;
}

.menu-docente {
  align-items: center;
  height: 6rem;
  font-size: .95rem;
  display: flex;
}

.logoUniverso {
  background-image: url("logo_universo.10238e4a.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16%;
  height: 5rem;
  margin-left: 12rem;
}

.contenedorEnlaces {
  justify-content: space-evenly;
  width: 40%;
  display: flex;
}

.contenedorOrganizadores a, .contenedorPaneles a, .contenedorParaEscribir a, .contenedorJuegos a {
  color: #000;
  text-decoration: none;
}

.active {
  border-bottom: 2px solid #70569f;
  font-weight: bold;
}
/*# sourceMappingURL=index.e1f8f7ef.css.map */
