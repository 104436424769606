@font-face {
	font-family: "LibreFranklin";
	src: url("../../fonts/librefranklin-medium-webfont.woff2") format("woff2"),
		url("../../fonts/librefranklin-medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

body {
  /* width: 100vw; */
  height: 100vh;
  margin: 0;
	font-family: "LibreFranklin";
}

#app {
  width: 100%;
  /* height: 100%;
  overflow: hidden; */
}

/* Apartado del menu */
.menu-docente {
  height: 6rem;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
}

.logoUniverso {
  width: 16%;
  height: 5rem;
  margin-left: 12rem;  
  background-image: url("../img/logo_universo.svg");
  background-repeat: no-repeat;
  background-size: contain
}

.contenedorEnlaces {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
}

.contenedorOrganizadores a,
.contenedorPaneles a,
.contenedorParaEscribir a,
.contenedorJuegos a{
  text-decoration: none;
  color: black;
}

.active {
  border-bottom: 2px #70569F solid;
  font-weight: bold;
}